export default {
  data() {
    return {};
  },
  methods: {
    totalRoomAmount(data) {
      let total = 0;
      let gst = 0;
      let no_of_room = 0;
      let totalAmount = 0;
      let subTotal = 0;
      let gstAmount = 0;
      if ( data ) {
        if ( _.isArray(data) ) {
          _.map(data, (i) => {
            gst += i.gst;
            totalAmount += parseFloat(i.rate * i.no_of_room);
            no_of_room += parseInt(i.no_of_room);
            subTotal += parseFloat(i.rate * i.no_of_room);
            gstAmount += parseFloat(i.rate * i.no_of_room * ( i.gst / 100 ));
            total += parseFloat(( ( ( i.rate * i.no_of_room ) * i.gst ) / 100 ) + ( i.rate * i.no_of_room ));
          });
        } else {
          gst = data.gst;
          totalAmount = parseFloat(data.rate * data.no_of_room);
          no_of_room = parseInt(data.no_of_room);
          subTotal += parseFloat(data.rate * data.no_of_room);
          gstAmount = parseFloat(data.rate * data.no_of_room * ( data.gst / 100 ));
          total = parseFloat(( ( ( data.rate * data.no_of_room ) * data.gst ) / 100 ) + ( data.rate * data.no_of_room ));
        }
      }

      return {
        'total': this.roundFloatNumberWithoutTrailingZeroes(total, 2),
        'gst': parseInt(gst),
        'amount': totalAmount,
        'subTotal': subTotal,
        'gstAmount': gstAmount,
        'no_of_room': no_of_room
      };
    },
    totalEventAmount(event) {
      let amount = 0;
      let rate = 0;
      let gst = 0;
      let total = 0;
      let gstAmount = 0;
      let guest = 0;

      if ( event ) {
        _.map(event, (data) => {
          if ( data.fnb !== null && data.fnb !== undefined ) {
            amount += this.totalAmountS(data.fnb).amount;
            gstAmount += this.totalAmountS(data.fnb).gstAmount;
            total += this.totalAmountS(data.fnb).total;
          }

          if ( data.fnbExtras !== null ) {
            amount +=
              ( this.totalAmountS({
                'fnbExtras': data.fnbExtras
              }).amount );

            gstAmount += ( this.totalAmountS({
              'fnbExtras': data.fnbExtras
            }).gstAmount );

            total += ( this.totalAmountS({
              'fnbExtras': data.fnbExtras
            }).total );

          }

          if ( data.decors !== null ) {
            amount += ( this.totalAmountS({
              'decors': data.decors
            }).amount );

            gstAmount += ( this.totalAmountS({
              'decors': data.decors
            }).gstAmount );

            total += ( this.totalAmountS({
              'decors': data.decors
            }).total );
          }

          if ( data.otherCharges !== null ) {
            amount += ( this.totalAmountS({
              'otherCharges': data.otherCharges
            }).amount );

            gstAmount += ( this.totalAmountS({
              'otherCharges': data.otherCharges
            }).gstAmount );

            total += ( this.totalAmountS({
              'otherCharges': data.otherCharges
            }).total );
          }
        });
      }
      return {
        'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
        'total': ( total > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
        'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
        'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
        'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
      };
    },
    totalAmountS(data) {
      let amount = 0;
      let rate = 0;
      let gst = 0;
      let total = 0;
      let gstAmount = 0;
      let guest = 0;

      if ( data ) {
        //decors
        if ( data.decors !== undefined && data.decors !== null ) {
          if ( Array.isArray(data.decors) ) {
            _.map(data.decors, (i) => {
              if ( i.total !== undefined ) {
                amount += parseFloat(i.total.amount);
                gst = parseFloat(i.total.gst);
              } else {
                if ( i.rate !== undefined ) {
                  amount += parseFloat(i.rate);
                  rate += parseFloat(i.rate);
                }
                gst = parseFloat(i.gst);
              }
            });


            gstAmount = parseFloat(( amount * gst ) / 100);
            total = parseFloat(gstAmount + amount);
          }
          return {
            'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
            'gst': gst ? parseInt(gst) : 0,
            'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
            'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
            'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
            'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
          };
        }

        //fnb extras
        if ( data.fnbExtras !== undefined && data.fnbExtras !== null ) {
          if ( Array.isArray(data.fnbExtras) ) {
            _.map(data.fnbExtras, (i) => {
              if ( i.total !== undefined ) {
                amount += parseFloat(i.total.amount);
                gst = parseFloat(i.total.gst);
              } else {
                if ( i.rate !== undefined ) {
                  amount += parseFloat(( i.no_of_pax !== undefined ) ? ( i.rate * i.no_of_pax ) : i.rate);
                  rate += parseFloat(i.rate);
                  guest += ( i.no_of_pax !== undefined ) ? parseFloat(i.no_of_pax) : 0;
                }
                if ( i.rent !== undefined ) {
                  amount += parseFloat(i.rent);
                  rate += parseFloat(i.rent);
                }
                gst = parseFloat(i.gst);
              }
            });

            gstAmount = parseFloat(( amount * gst ) / 100);
            total = parseFloat(gstAmount + amount);
          }
          return {
            'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
            'gst': gst ? parseInt(gst) : 0,
            'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
            'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
            'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
            'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
          };
        }

        //other charges
        if ( data.otherCharges !== undefined && data.otherCharges !== null ) {
          if ( Array.isArray(data.otherCharges) ) {
            gstAmount = 0;
            _.map(data.otherCharges, (i) => {
              let sub_total = 0;
              if ( i.total !== undefined ) {
                sub_total = i.total.amount;
                amount += parseFloat(sub_total);
                gst = parseFloat(i.total.gst);
              } else {
                if ( i.rate !== undefined ) {
                  sub_total = ( ( i.no_of_pax !== undefined ) ? ( i.rate * i.no_of_pax ) : i.rate );
                  amount += parseFloat(sub_total);
                  rate += parseFloat(i.rate);
                  guest += ( i.no_of_pax !== undefined ) ? parseFloat(i.no_of_pax) : 0;
                }
                if ( i.rent !== undefined ) {
                  amount += parseFloat(i.rent);
                  rate += parseFloat(i.rent);
                }
                gst = parseFloat(i.gst);
              }


              gstAmount += parseFloat(( sub_total * gst ) / 100);

            });
          }

          total = parseFloat(gstAmount + amount);

          return {
            'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
            'gst': gst ? parseInt(gst) : 0,
            'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
            'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
            'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
            'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
          };
        }

        //set fnb
        else {
          if ( data.fnb !== undefined && data.fnb !== null ) {
            if ( ( data.rate !== undefined && data.rate ) ) {
              amount += parseFloat(( data.no_of_pax !== undefined ) ? ( data.rate * data.no_of_pax ) : data.rate);
              rate += parseFloat(data.rate);
              guest += ( data.no_of_pax !== undefined ) ? data.no_of_pax : 0;
            }

            gst = data.gst;

          } else {

            if ( ( data.rate !== undefined && data.rate ) ) {
              amount += parseFloat(( data.no_of_pax !== undefined ) ? ( data.rate * data.no_of_pax ) : data.rate);
              rate += parseFloat(data.rate);
              guest += ( data.no_of_pax !== undefined ) ? data.no_of_pax : 0;
            }

            gst = data.gst;

          }
          gstAmount += parseFloat(( ( gst * amount ) / 100 ));
          total += parseFloat(gstAmount + amount);

          return {
            'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
            'gst': gst ? parseInt(gst) : 0,
            'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
            'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
            'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
            'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
          };

        }
      }

      return {
        'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
        'gst': gst ? parseInt(gst) : 0,
        'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
        'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
        'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
        'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
      };
    },
    totalAmount(data) {
      let amount = 0;
      let rate = 0;
      let gst = 0;
      let total = 0;
      let gstAmount = 0;
      let guest = 0;
      if ( data ) {
        if ( Array.isArray(data) ) {
          _.map(data, (i) => {
            if ( i.total !== undefined ) {
              amount += parseFloat(i.total.amount);
              gst += parseFloat(i.total.gst);
            } else {
              if ( i.rate !== undefined ) {
                amount += parseFloat(( i.no_of_pax !== undefined ) ? ( i.rate * i.no_of_pax ) : i.rate);
                rate += parseFloat(i.rate);
                guest += ( i.no_of_pax !== undefined ) ? parseFloat(i.no_of_pax) : 0;
              }
              if ( i.rent !== undefined ) {
                amount += parseFloat(i.rent);
                rate += parseFloat(i.rent);
              }
              if ( i.gst ) {
                gst += parseFloat(i.gst);
              }
            }
            gstAmount += parseFloat(( amount * gst ) / 100);
            total += ( parseFloat(gstAmount + amount) );
          });
        } else {
          if ( data !== undefined ) {
            if ( ( data.rate !== undefined && data.rate ) ) {
              amount += parseFloat(( data.no_of_pax !== undefined ) ? ( data.rate * data.no_of_pax ) : data.rate);
              rate += parseFloat(data.rate);
              guest += ( data.no_of_pax !== undefined ) ? data.no_of_pax : 0;
            }
            if ( data.hall_rent ) {
              amount += parseFloat(data.hall_rent);
              rate += parseFloat(data.hall_rent);
            }
            gst += ( data.gst == undefined ) ? data.hall_gst : data.gst;
            gstAmount += parseFloat(( ( gst * amount ) / 100 ));
            total += parseFloat(gstAmount + amount);
          }
        }
      }
      return {
        'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
        'gst': gst ? parseInt(gst) : 0,
        'total': this.roundFloatNumberWithoutTrailingZeroes(total, 2),
        'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
        'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
        'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
      };
    },
    roundFloatNumberWithoutTrailingZeroes(num, dp) {
      var numToFixedDp = Number(num).toFixed(dp);
      return Number(numToFixedDp);
    },
    totalAmountPreferences(data) {
      let amount = 0;
      let rate = 0;
      let total = 0;
      let guest = 0;
      let gstAmount = 0;

      if ( data ) {
        //decors
        if ( data ) {
          if ( Array.isArray(data) ) {
            _.map(data, (i) => {
              if ( i.rate !== undefined ) {
                if ( i.no_of_pax && i.rate ) {
                  amount = parseFloat(i.rate * i.no_of_pax);
                }
                if ( i.rate && !i.no_of_pax ) {
                  amount = parseFloat(i.rate);
                }
                rate += parseFloat(i.rate);
                guest += ( i.no_of_pax !== undefined ) ? data.no_of_pax : 0;
              }

              if (i.gst) {

                const gst = parseFloat(i.gst);
                gstAmount += parseFloat(( amount * gst ) / 100);
                amount += (parseFloat(amount) * gst / 100)
              }

              total += amount;
            });

          } else {
            if ( ( data.rate !== undefined && data.rate ) ) {
              if ( data.no_of_pax && data.rate ) {
                amount = parseFloat(data.rate * data.no_of_pax);
              }
              if ( data.rate && !data.no_of_pax ) {
                amount = data.rate;
              }
              rate += parseFloat(data.rate);
              guest += ( data.no_of_pax !== undefined ) ? data.no_of_pax : 0;
            }

            amount = parseFloat(amount);

            if (data.gst) {
              gstAmount += parseFloat(( amount * data.gst ) / 100);

              amount += (amount * data.gst / 100)
            }

            total = parseFloat(amount);

          }

          return {
            'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
            'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
            'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
            'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
            'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
          };
        }

        return {
          'amount': amount ? this.roundFloatNumberWithoutTrailingZeroes(amount, 2) : 0,
          'gstAmount': gstAmount ? this.roundFloatNumberWithoutTrailingZeroes(gstAmount, 2) : 0,
          'total': ( this.roundFloatNumberWithoutTrailingZeroes(total, 2) > 0 ) ? this.roundFloatNumberWithoutTrailingZeroes(total, 2) : 0,
          'rate': rate ? this.roundFloatNumberWithoutTrailingZeroes(rate, 2) : 0,
          'guest': guest ? this.roundFloatNumberWithoutTrailingZeroes(guest, 2) : 0,
        };
      }
    },
  },
};
