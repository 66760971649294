<template>
  <PLayout>
    <PLayoutSection></PLayoutSection>
  </PLayout>
</template>

<script>

import { mapGetters, mapState } from "vuex";
import { request } from "@/core/services/Request";
import moment from "moment-timezone";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
import { getAuthUser } from "@/util/globalFunction";

const FILTER_STATE = {
  event_id: null,
  user_id: null,
  property_id: null,
  start_date: null,
  end_date: null,
  sub_user_id: null,
  year: null,
};

export default {
  mixins: [totalAmountMixin],
  data() {
    return {
      pie_source_number: [],
      pie_source_labels: [],
      pie_drop_analytics_number: [],
      pie_drop_analytics_labels: [],
      filters: { ...FILTER_STATE },
      lineChart: {
        series: [],
        chartOptions: {
          chart: {
            type: 'line',
            height: '500',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#77B6EA', '#545454', '#ff0000'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: 'Quotation, Booking & Events Information',
            align: 'left'
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            title: {
              text: 'Month'
            }
          },
          yaxis: {
            title: {
              text: 'Total'
            },
            labels: {
              type: 'number',
              formatter: function (value) {
                return parseInt(value);
              }
            },
            max: null,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        },
      },
      source: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      dropAnalytics: {
        series: [],
        chartOptions: {
          labels: [],
          chart: {
            width: 380,
            type: 'donut',
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      quotationBarChart: {
        series: [{
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            labels: {
              type: 'datetime',
            }
          },
          yaxis: {
            labels: {
              type: 'number',
            }
          }
        },
      },
      dropAanalyticsPieChartData: [],
      followUpCount: 0,
      leadCount: 0,
      brochureCount: 0,
      quotationCount: 0,
      bookingCount: 0,
      bookingAmount: 0,
      eventCount: 0,
      totalLostEventCount: 0,
      totalCollectedCount: 0,
      totalOutStandingCount: 0,
      qBarChart: [],
      bBarChart: [],
      dropdowns: {
        events: [],
        subUsers: [],
      },
      hot_count: 0,
      hot_amount: 0,
      qualified_count: 0,
      qualified_amount: 0,
      won_count: 0,
      won_amount: 0,
      lost_count: 0,
      lost_amount: 0,
      standard_count: 0,
      standard_amount: 0,
      event_amount: 0,
      quotationAmount: 0,
      numberOfCollectedBooking: 0,
      numberOfOutStandingBooking: 0,
    };
  },
  mounted() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    const currentYear = moment().endOf('year').format('YYYY');
    this.filters.start_date = startOfMonth;
    this.filters.end_date = endOfMonth;
    this.filters.year = currentYear;

    this.getDetails();
    this.getEventType();
  },
  methods: {
    async getDetails() {
      this.filters.user_id = getAuthUser().id;
      this.getSubUser();


      try {
        const response = await request({
          url: `/dashboards/admin/count/box`,
          method: 'post',
          data: { ...this.filters },
        });

        const { data } = response.data;

        const { drop_analytics, sources } = data.pie_charts;
        this.dropAanalyticsPieChartData = drop_analytics;

        this.pie_source_number = _.map(sources, (i) => i.count);
        this.pie_source_labels = _.map(sources, (i) => {
          return i.sources.source_name + " [" + i.count + "]";
        });

        this.pie_drop_analytics_number = _.map(drop_analytics, (i) => i.count);
        this.pie_drop_analytics_labels = _.map(drop_analytics, (i) => i.title);


        const {
          total_leads,
          total_follow_up,
          brochure_count,
          quotation_count,
          booking_count,
          event_count,
          lost_event_count,
          total_collected_amount,
          total_outstanding_amount,
          booking_amount,
          hot_count,
          hot_amount,
          qualified_count,
          qualified_amount,
          won_count,
          won_amount,
          lost_count,
          lost_amount,
          standard_count,
          standard_amount
        } = data.count;
        const { event_amount, quotation_amount, total_Collected } = data.count;
        this.numberOfCollectedBooking = total_Collected;
        this.event_amount = event_amount;
        this.hot_count = hot_count;
        this.hot_amount = hot_amount;
        this.standard_count = standard_count;
        this.standard_amount = standard_amount;
        this.qualified_count = qualified_count;
        this.qualified_amount = qualified_amount;
        this.lost_count = lost_count;
        this.lost_amount = lost_amount;
        this.won_count = won_count;
        this.won_amount = won_amount;
        this.leadCount = total_leads;
        this.followUpCount = total_follow_up;
        this.brochureCount = brochure_count;
        this.quotationCount = quotation_count;
        this.bookingCount = booking_count;
        let Bookingtotal = 0;
        _.map(booking_amount, (i) => {
          Bookingtotal += parseFloat(this.totalEventAmount(i.events).amount) + parseFloat(this.totalRoomAmount(i.rooms).amount);
        });
        this.bookingAmount = Bookingtotal;
        let Quotationtotal = 0;
        _.map(quotation_amount, (i) => {
          Quotationtotal += parseFloat(this.totalEventAmount(i.events).amount) + parseFloat(this.totalRoomAmount(i.rooms).amount);
        });
        this.quotationAmount = Quotationtotal;

        this.eventCount = event_count;
        this.totalLostEventCount = lost_event_count;
        this.totalCollectedCount = total_collected_amount;
        let total = 0;
        _.map(total_outstanding_amount, (i) => {
          total += parseFloat(this.totalEventAmount(i.events).total) + parseFloat(this.totalRoomAmount(i.rooms).total);
        });
        this.totalOutStandingCount = total - total_collected_amount;
        this.numberOfOutStandingBooking = total_outstanding_amount.length;
        const { quotations, bookings, events } = data.line_charts;

        const allQuotationMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const allBookingMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const allEventsMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const allBarQuotationMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const allBarBookingMonthViseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        this.qBarChart = _.filter(data.bar_charts, (i) => i.type === 'default');
        this.bBarChart = _.filter(data.bar_charts, (i) => i.type === 'booking');

        _.filter(this.lineChart.chartOptions.xaxis.categories, (i, index) => {
          allQuotationMonthViseData[index] = _.filter(quotations, (q) => moment(q.dates).format("MMM") === i).length;
          allBookingMonthViseData[index] = _.filter(bookings, (q) => moment(q.dates).format("MMM") === i).length;
          allEventsMonthViseData[index] = ( _.filter(events, (q) => moment(q.dates).format("MMM") === i).length );
          allBarQuotationMonthViseData[index] = _.map(_.filter(this.qBarChart, (q) => moment(q.dates).format("MMM") === i), (k) => {
            return parseFloat(this.totalEventAmount(k.events).total) + parseFloat(this.totalRoomAmount(k.rooms).total);
          });
          allBarBookingMonthViseData[index] = _.map(_.filter(this.bBarChart, (q) => moment(q.dates).format("MMM") === i), (k) => {
            return parseFloat(this.totalEventAmount(k.events).total) + parseFloat(this.totalRoomAmount(k.rooms).total);
          });
        });


        //bar charts
        this.quotationBarChart.series = [
          {
            name: "Quotations",
            data: _.map(allBarQuotationMonthViseData, (t) => _.sum(t)),
          },
          {
            name: "Bookings",
            data: _.map(allBarBookingMonthViseData, (t) => _.sum(t)),
          }
        ];

        this.lineChart.series = [
          {
            name: "Quotation",
            data: allQuotationMonthViseData
          },
          {
            name: "Events",
            data: allEventsMonthViseData
          },
          {
            name: "Bookings",
            data: allBookingMonthViseData
          },
        ];

        this.lineChart.chartOptions.yaxis.max = ( _.sortBy(_.uniq(_.concat(allBookingMonthViseData, _.concat(allQuotationMonthViseData, allEventsMonthViseData)))).reverse() + 1 );

      } catch (e) {

      }
    },
    async getEventType() {
      try {
        const response = await request({
          url: '/dropdowns/event/type',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.events = _.map(data, (i) => {
          return {
            id: i,
            label: i,
          };
        });
      } catch (e) {

      }
    },
    async getSubUser() {
      try {
        const response = await request({
          url: `/dropdowns/sub/user`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.subUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
  },
  computed: {
    years() {
      const allYear = Array.from({ length: new Date().getFullYear() - 2000 }, (value, index) => 2020 + index);
      return allYear.map((item) => {
        return {
          id: item,
          label: item,
        };
      });
    },
    chartOptions: function () {
      return {
        labels: this.pie_source_labels,
        dataLabels: {
          type: 'number',
          formatter: function (value) {
            return parseInt(value);
          }
        },

      };
    },
    chartOptions1: function () {
      return {
        labels: this.pie_drop_analytics_labels
      };
    },
    ...mapGetters({ getPropertyList: 'getPropertyList' }),
    ...mapState([
      'global',
    ]),
  }
};
</script>

<style scoped>
#count-card .card .card-body {
  padding: 0;
  padding-bottom: 40px;
}
</style>
